import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import requiresAuth from "app/RequiresAuth";
import "font-awesome/css/font-awesome.min.css";
import "static/styles/main.css";
import Login from "routes/Login/Login";
import NotFound from "routes/NotFound";
import App from "./App";
import history from "./store/history";
import { appInsights, reactPlugin } from "appInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const Root = ({ store }) => {
  useEffect(() => {
    appInsights.trackPageView();
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>Something went wrong</h1>}
        appInsights={reactPlugin}
      >
        <Provider store={store}>
          <Router history={history} basename="/">
            <Switch>
              <Route exact path="/login" name="Login" component={Login} />
              {/* <Route exact path={`${process.env.PUBLIC_URL}/404`} name="404 Not Found" component={NotFound} /> */}
              <Route
                exact
                path="/404"
                name="404 Not Found"
                component={NotFound}
              />
              <Route
                path="/"
                name="Admin Panel"
                component={requiresAuth(App)}
              />
            </Switch>
          </Router>
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default Root;
