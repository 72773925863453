import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import { TableHeaderColumn } from "react-bootstrap-table";
import styled from "styled-components";
import Loading from "../../components/Loading";
import AgileUserCard from "./components/UserCard/AgileUserCard";
import UserActions from "./components/UserActions/UserActions";
import { ApiHelperFunc } from "../../services/AgileApi";
import StyledBootstrapTable from "../../components/styled/BootstrapTable";

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const StyledCardHeader = styled(CardHeader)`{
    display: flex;
    justify-content: space-between;
  
    .switch {
      margin-left: auto;
      margin-bottom: 0;
    }
  
    .switch .switch-input:not(:checked) + .switch-label {
      background-color: #a4b7c1;
      border-color: #869fac;
    }
    .switch .switch-input:not(:checked) + .switch-label + .switch-handle {
      border-color: #869fac;
    }
  }
}
`;

export default function AgileUser(props) {
  const [user, setUser] = useState([]);

  const onFetch = useCallback(async () => {
    const parsed = parseInt(props.id, 10);
    if (isNaN(parsed)) return;
    const USER_QUERY = {
      query: `query user(
        $userId: Int!
      )
      {
        user(id: $userId)
        {
          id
          firstName
          lastName
          email
          role
          welcomeStatus
          currentClassroom{
            id
            className
            instructors {
              type
              user{
                id
                firstName
                lastName
                email
              }
            }
          }
          licenses{
            id
            registrationDate
            expirationDate
            acceptanceDate
            classStudentRuns {
              id
              runSequenceNumber,
              status
              team {
                id
                currentStatus
                currentWeekNumber
                endDate
              }
            }
          }
        }
      }`,
      variables: {
        userId: parsed,
      },
    };
    return ApiHelperFunc(USER_QUERY).then((response) => {
      setUser(response.data.data.user);
    });
  }, [props.id]);

  useEffect(() => {
    onFetch();
  }, [onFetch]);

  const updateUser = async (payload) => {
    if (payload.id && payload.id !== null) {
      const UPDATE_USER_MUTATION = {
        query: `mutation updateUser($id: Int!, $email: String!) {
          updateUser(id: $id, email: $email) {
            id
          }
        }`,
        variables: {
          ...payload,
        },
      };
      return ApiHelperFunc(UPDATE_USER_MUTATION).then((response) => {
        onFetch();
      });
    }
  };

  const resendVerificationEmail = async (payload) => {
    if (payload.id && payload.id !== null) {
      const RESEND_VERIFICATION_EMAIL = {
        query: `mutation resendVerificationEmail($id: Int!) {
          resendVerificationEmail(id: $id) {
            id
          }
        }`,
        variables: {
          id: payload.id,
        },
      };

      const res = ApiHelperFunc(RESEND_VERIFICATION_EMAIL).then((response) => {
        return response;
      });

      return res;
    }
  };

  const updateLicense = async (payload) => {
    if (payload.id && payload.id !== null) {
      const UPDATE_LICENSE_MUTATION = {
        query: `mutation updateLicense($id: Int!, $expirationDate: DateTime!) {
          updateLicense(id: $id,  expirationDate: $expirationDate) {
            id
          }
        }`,
        variables: {
          ...payload,
        },
      };

      await ApiHelperFunc(UPDATE_LICENSE_MUTATION);

      await onFetch();
    }
  };

  const deleteRun = async (row) => {
    console.log(row);
    if (row.id && row.id !== null) {
      const DELETE_TEAM_MUTATION = {
        query: `mutation deleteTeam(
          $teamId: Int!
          $classStudentRunId: Int!
        ) {
          deleteTeam(
            teamId: $teamId, 
            classStudentRunId: $classStudentRunId
          ) {
            id
          }
        }`,
        variables: {
          teamId: row.team.id,
          classStudentRunId: row.id,
        },
      };

      return ApiHelperFunc(DELETE_TEAM_MUTATION).then(() => {
        onFetch();
      });
    }
  };

  const formatActions = (cell, row, formatExtraData, rowIdx) => {
    if (row.team && row.team !== null && row.team.id && row.team.id !== "") {
      return (
        <div>
          <Button
            color="danger"
            onClick={() => {
              deleteRun(row);
            }}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      );
    }
    return <span />;
  };

  const showCurrentWeek = (cell, row) => {
    if (cell && cell.currentWeekNumber) {
      return cell.currentWeekNumber;
    } else {
      return "";
    }
  };

  const options = {
    noDataText: "Student has no runs in the database",
  };

  return (
    <Fragment>
      <StyledRow>
        <Col md="12">
          {user === undefined || user === null ? (
            <Loading />
          ) : (
            <AgileUserCard user={user} />
          )}
        </Col>
      </StyledRow>

      {user === undefined ||
      user === null ||
      user.licenses === undefined ||
      user.licenses === null ||
      user.licenses.length < 1 ||
      user.licenses[0] === undefined ||
      user.licenses[0] === null ? (
        <Loading />
      ) : (
        <UserActions
          user={user}
          updateUser={updateUser}
          updateLicense={updateLicense}
          resendVerificationEmail={resendVerificationEmail}
        />
      )}

      <StyledRow>
        <Col md="8">
          <Card>
            <StyledCardHeader>
              <span style={{ marginRight: "5px" }}>
                <b>User Runs</b>
              </span>
              <Label className="switch switch-default switch-primary">
                <Button onClick={onFetch}>
                  {" "}
                  <i className="fa fa-refresh" />
                </Button>
              </Label>
            </StyledCardHeader>
            <CardBody>
              {user === undefined ||
              user === null ||
              user.licenses === undefined ||
              user.licenses === null ||
              user.licenses.length < 1 ||
              user.licenses[0] === undefined ||
              user.licenses[0] === null ||
              user.licenses[0].classStudentRuns === undefined ||
              user.licenses[0].classStudentRuns === null ||
              user.licenses[0].classStudentRuns.length < 1 ? (
                <Loading />
              ) : (
                <Fragment>
                  <StyledBootstrapTable
                    data={user.licenses[0].classStudentRuns}
                    options={options}
                  >
                    <TableHeaderColumn
                      width="20%"
                      dataAlign="center"
                      isKey={true}
                      dataField="runSequenceNumber"
                    >
                      Run Seq #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="30%"
                      dataAlign="center"
                      dataField="status"
                    >
                      Status
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      width="30%"
                      dataAlign="center"
                      dataField="team"
                      dataFormat={showCurrentWeek}
                    >
                      Current Project Week
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      width="30%"
                      className="col-last"
                      editable={false}
                      columnClassName="col-last"
                      dataAlign="center"
                      dataFormat={formatActions}
                    >
                      <div>Actions</div>
                    </TableHeaderColumn>
                  </StyledBootstrapTable>
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </StyledRow>
    </Fragment>
  );
}
